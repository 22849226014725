/**--------------------------------------
 * App Menu
 *--------------------------------------*/
"use strict";
import { WINDOW, DOCUMENT, CLASS_BODY, CLASS_HIDDEN } from "@utils/constants";
import { isExists, isTabletAndMobile } from "@utils/validate";

const Menu = function () {
  const EL_NAV = "#main-menu";
  const EL_NAV_LINK = ".bi";
  const EL_DROPDOWN = ".dropdown";
  const EL_DROPDOWN_CONTENT = ".dropdown-content";
  const IS_ACTIVE = "active";
  const STR_OPEN_MENU = "open-menu";
  const BTN_TOGGLE_MENU = "#toggle-menu";

  /**
   * openMenu function
   */
  this.openMenu = function () {
    this.removeActionWhenResize();
    $(CLASS_BODY).addClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * closeMenu function
   */
  this.closeMenu = function () {
    $(CLASS_BODY).removeClass(`${STR_OPEN_MENU} ${CLASS_HIDDEN}`);
  };
  /**
   * handleClickMenu function
   */
  this.handleClickMenu = function () {
    let self = this;
    $(BTN_TOGGLE_MENU).on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(CLASS_BODY).hasClass(STR_OPEN_MENU) ? self.closeMenu() : self.openMenu();
    });

    DOCUMENT.on("click", ".overlay", function (e) {
      e.stopPropagation();
      self.closeMenu();
    });
  };
  /**
   * handleMenuDropdown
   * @return {void}
   */
  this.handleMenuDropdown = function () {
    $(EL_NAV).each(function () {
      let self = $(this),
        elClick = self.find(EL_NAV_LINK);

      elClick.on("click", function (e) {
        if (isTabletAndMobile()) {
          e.stopPropagation();
          e.preventDefault();
          const parent = $(this).parents(EL_DROPDOWN);
          parent.toggleClass(IS_ACTIVE).find(EL_DROPDOWN_CONTENT).slideToggle(250);
          $(EL_NAV_LINK)
            .not($(this))
            .parents(EL_DROPDOWN)
            .removeClass(IS_ACTIVE)
            .find(EL_DROPDOWN_CONTENT)
            .stop()
            .slideUp(250);
        }
      });
    });
  };
  this.removeActionWhenResize = function () {
    $(CLASS_BODY).find(EL_DROPDOWN).removeClass(IS_ACTIVE);
    $(CLASS_BODY).find(EL_DROPDOWN_CONTENT).removeAttr("style");
  };

  this.init = function () {
    if (!isExists(EL_NAV)) return;

    let self = this;
    this.handleClickMenu();
    this.handleMenuDropdown();

    WINDOW.resize(function () {
      if (!isTabletAndMobile()) {
        self.removeActionWhenResize();
        self.closeMenu();
      }
    });
  };

  return this.init();
};
export default Menu;
