/**--------------------------------------
 * Main
 *--------------------------------------*/
"use strict";
import $ from "jquery";
window.app = app;
window.jQuery = $;

// Common
$(function () {
  new app.Core();
  new app.Menu();
  new app.Custom();
});

// Slider
$(function () {
  new app.Slider({
    el: "#main-slider",
    options: {
      arrows: false,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1
    }
  });
  new app.Slider({
    el: ".partner-slider",
    options: {
      slidesToShow: 8,
      slidesToScroll: 4,
      dots: false,
      autoplay: true,
      responsive: [
        { breakpoint: 1200, settings: { slidesToShow: 7, slidesToScroll: 3 } },
        { breakpoint: 992, settings: { slidesToShow: 5, slidesToScroll: 3 } },
        { breakpoint: 768, settings: { slidesToShow: 4, slidesToScroll: 2 } },
        { breakpoint: 575, settings: { slidesToShow: 3, slidesToScroll: 3 } }
      ]
    }
  });
  new app.Slider({
    el: ".news-slider",
    options: {
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: false,
      responsive: [
        { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }
      ]
    }
  });

  new app.Slider({
    el: ".project-slider",
    options: {
      slidesToShow: 5,
      slidesToScroll: 5,
      dots: false,
      responsive: [
        { breakpoint: 992, settings: { slidesToShow: 3, slidesToScroll: 3 } },
        { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 } }
      ]
    }
  });

  new app.SliderThumbnail({
    elParent: ".project-content",
    elChild: ".project-thumb",
    optionParent: {
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    optionChild: {
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 575,
          settings: {
            arrows: false,
            centerMode: false,
            slidesToShow: 3
          }
        }
      ]
    }
  });
  new app.SliderThumbnail({
    elParent: ".about-content",
    elChild: ".about-thumb",
    optionParent: {
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    },
    optionChild: {
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      infinite: true,
      centerPadding: 0,
      variableWidth: false,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }
  });
});

// Init Tooltip
$(function () {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new app.Tooltip(tooltipTriggerEl);
  });
});
