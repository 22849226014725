/**--------------------------------------
 * App Slider
 *--------------------------------------*/
"use strict";
import { isExists } from "@utils/validate";

/**
 * Slider
 * @param {this} param0
 */
export const Slider = function ({ el, options }) {
  this.target = $(el);
  /**
   * Define options
   */
  this.options = $.extend(
    {
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      dots: true,
      arrows: true,
      swipe: "swipe",
      centerPadding: null,
      adaptiveHeight: true,
      pauseOnHover: false,
      draggable: false,
      swipe: false,
      autoplaySpeed: 4000,
      prevArrow:
        '<button type="button" class="slide-prev"><i class="bi bi-chevron-compact-left"></i></button>',
      nextArrow:
        '<button type="button" class="slide-next"><i class="bi bi-chevron-compact-right"></i></button>',
      responsive: []
    },
    options
  );

  /**
   * Init function
   */
  this.init = function () {
    if (!isExists(el)) return;

    this.target.slick({ ...this.options });
  };

  return this.init();
};

/**
 * SliderThumbnail
 * @param {object} param0
 */
export const SliderThumbnail = function ({ elParent, elChild, optionParent, optionChild }) {
  this._elParent = $(elParent);
  this._elChild = $(elChild);
  /**
   * Define options
   */
  this.optionParent = $.extend(
    {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
      asNavFor: elChild,
      prevArrow:
        '<button type="button" class="slick-prev"><i class="bi bi-arrow-left-circle"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="bi bi-arrow-right-circle"></i></button>'
    },
    optionParent
  );
  this.optionChild = $.extend(
    {
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: elParent,
      dots: false,
      arrows: false,
      focusOnSelect: true,
      prevArrow:
        '<button type="button" class="slick-prev"><i class="bi bi-arrow-left-circle"></i></button>',
      nextArrow:
        '<button type="button" class="slick-next"><i class="bi bi-arrow-right-circle"></i></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 575,
          settings: {
            arrows: false,
            centerMode: false,
            slidesToShow: 3
          }
        }
      ]
    },
    optionChild
  );

  /**
   * Init function
   */
  this.init = function () {
    if (!isExists(elParent) && !isExists(elChild)) return;

    this._elParent.slick({ ...this.optionParent });
    this._elChild.slick({ ...this.optionChild });
  };

  return this.init();
};
