/**--------------------------------------
 * App Custom
 *--------------------------------------*/
"use strict";
import { WINDOW } from "@utils/constants";
import { isExists, isMobile } from "@utils/validate";

const Custom = function () {
  /**
   * handleSetPositionSliderArrow
   */
  this.handleSetPositionSliderArrow = () => {
    const EL_SLIDER = ".center-slider";
    const EL_SLIDER_ITEM = ".center-slider-item.slick-current";
    const EL_SLIDER_ITEM_IMAGE = ".center-slider-thumb img";
    const EL_SLIDE_PREV = ".slide-prev";
    const EL_SLIDE_NEXT = ".slide-next";
    const EL_SLICK_ARROW = ".slick-arrow";

    if (!isExists(EL_SLIDER)) return;

    /**
     * handlePositonArrow function
     */
    const handePositionArrowOnMobile = () => {
      const heightEl = $(EL_SLIDER).find(EL_SLIDER_ITEM_IMAGE).height();
      $(EL_SLIDER)
        .find(EL_SLICK_ARROW)
        .css("top", heightEl / 2);
    };

    /**
     * handlePositonArrow function
     */
    const handlePositonArrow = () => {
      const widthDevice = WINDOW.width();
      const widthSlider = $(EL_SLIDER).find(EL_SLIDER_ITEM).width();
      $(EL_SLIDER)
        .find(EL_SLIDE_PREV)
        .css("left", (widthDevice - widthSlider) / 2 - 40);
      $(EL_SLIDER)
        .find(EL_SLIDE_NEXT)
        .css("right", (widthDevice - widthSlider) / 2 - 40);
    };

    if ($(EL_SLIDER).hasClass("slick-initialized")) {
      isMobile() ? handePositionArrowOnMobile() : handlePositonArrow();
    }

    $(EL_SLIDER).on("afterChange", function (event, slick, currentSlide, nextSlide) {
      !isMobile() && handlePositonArrow();
    });
  };

  /**
   * setHeightOnCardService
   */
  this.startListMaxHeight = $tag => {
    function setHeight(s) {
      if (!isMobile()) {
        var max = 0;
        s.each(function () {
          var h = $(this).height();
          max = Math.max(max, h);
        })
          .height("")
          .height(max);
      } else {
        resetStyle($tag);
      }
    }
    function resetStyle(element) {
      element.each(function () {
        $(this).removeAttr("style");
      });
    }
    WINDOW.on("ready load", setHeight($tag));
    WINDOW.resize(() => {
      resetStyle($tag);
      setHeight($tag);
    });
  };

  /**
   * init function
   */
  this.init = function () {
    let self = this;

    // Set height
    const el = ".section-service";
    isExists(el) &&
      $(el).each(function () {
        self.startListMaxHeight($(".card-service .navbar-nav", this));
      });

    // Set Position
    setTimeout(() => self.handleSetPositionSliderArrow());

    WINDOW.resize(function (e) {
      setTimeout(() => self.handleSetPositionSliderArrow(), 300);
    });
  };
  return this.init();
};
export default Custom;
