/**--------------------------------------
 * App Helpers
 *--------------------------------------*/
"use strict";
import { WINDOW } from "./constants";
/**
 * getWindowWidth function
 */
export const getWindowWidth = () => WINDOW.width();

/**
 * handleFormatNumber function
 * @param {number} num
 */
export const handleFormatNumber = num => {
  let si = [
    { val: 1, symbol: "" },
    { val: 1e3, symbol: "K" },
    { val: 1e6, symbol: "M" },
    { val: 1e9, symbol: "G" },
    { val: 1e12, symbol: "T" },
    { val: 1e15, symbol: "P" },
    { val: 1e18, symbol: "E" }
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  let lengSi = si.length - 1;
  for (i = lengSi; i > 0; i--) {
    if (num >= si[i].val) {
      break;
    }
  }
  return (num / si[i].val).toFixed(1).replace(rx, "$1") + si[i].symbol;
};
