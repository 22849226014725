/**--------------------------------------
 * App Core
 *--------------------------------------*/
"use strict";
import {
  CLASS_BODY,
  CLASS_HEADER,
  CLASS_ACTIVE,
  CLASS_PC,
  CLASS_SP,
  CLASS_HIDDEN,
  WINDOW
} from "@utils/constants";
import { isExists, isMobile } from "@utils/validate";
import { getWindowWidth } from "@utils/helpers";

const Core = function () {
  /**
   * userAgent function
   */
  this.userAgent = function () {
    let _ua = (function (u) {
      return {
        Tablet:
          (u.indexOf("windows") != -1 &&
            u.indexOf("touch") != -1 &&
            u.indexOf("tablet pc") == -1) ||
          u.indexOf("ipad") != -1 ||
          (u.indexOf("android") != -1 && u.indexOf("mobile") == -1) ||
          (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1) ||
          u.indexOf("kindle") != -1 ||
          u.indexOf("silk") != -1 ||
          u.indexOf("playbook") != -1,
        Mobile:
          (u.indexOf("windows") != -1 && u.indexOf("phone") != -1) ||
          u.indexOf("iphone") != -1 ||
          u.indexOf("ipod") != -1 ||
          (u.indexOf("android") != -1 && u.indexOf("mobile") != -1) ||
          (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1) ||
          u.indexOf("blackberry") != -1
      };
    })(window.navigator.userAgent.toLowerCase());
    _ua.Mobile || _ua.Tablet ? $(CLASS_BODY).addClass(CLASS_SP) : $(CLASS_BODY).addClass(CLASS_PC);
  };

  /**
   * initLoader
   */
  this.initLoader = function (el) {
    if (!isExists(el)) return;

    const elLoader = $(el);
    setTimeout(function () {
      elLoader.fadeOut(200);
      $(CLASS_BODY).removeClass(CLASS_HIDDEN);
    }, 1500);
  };

  /**
   * initSearchForm
   */
  this.initSearchForm = function () {
    if (!isExists(elContent)) return;

    const elToggle = ".search-form-trigger";
    const elClose = ".search-form-wrap .btn-close";
    const elContent = ".search-form-wrap";
    $(elToggle).on("click", function () {
      $(this).parents(CLASS_HEADER).find(elContent).addClass(CLASS_ACTIVE);
      $(this).parents(CLASS_HEADER).find("input").focus();
    });
    $(elClose).on("click", function () {
      $(this).parents(CLASS_HEADER).find(elContent).removeClass(CLASS_ACTIVE);
    });
  };

  /**
   * handleInputUploadFile
   */
  this.handleInputUploadFile = function (el) {
    if (!isExists(el)) return;

    $("form").on("change", el, function () {
      let fileName = $(this)
        .val()
        .replace(/.*(\/|\\)/, "");
      $(this).prev().text(fileName);
    });
  };

  /**
   * initFancybox
   */
  this.initFancybox = function () {
    const imagesFacybox = '[data-fancybox="images"]';
    isExists(imagesFacybox) && $(imagesFacybox).fancybox();

    const galleryFacybox = '[data-fancybox="gallery"]';
    isExists(galleryFacybox) && $(galleryFacybox).fancybox();

    const videoFacybox = '[data-fancybox="video"]';
    isExists(videoFacybox) && $(videoFacybox).fancybox();
  };

  /**
   * initButtonGoTop
   */
  this.initButtonGoTop = el => {
    if (!isExists(el)) return;

    WINDOW.scroll(function () {
      WINDOW.scrollTop() > 100 ? $(el).addClass("show") : $(el).removeClass("show");
    });

    $(el).on("click", function (e) {
      $("html, body").animate({ scrollTop: 0 }, 500);
      return false;
    });

    WINDOW.resize(function () {
      if (!isMobile()) {
        const getRightPositon = (getWindowWidth() - $(".container").width()) / 2;
        $(el).css("right", getRightPositon);
      } else {
        $(el).removeAttr("style");
      }
    });
    WINDOW.trigger("resize");
  };

  this.init = function () {
    this.userAgent();
    this.initLoader(".loader");
    this.initSearchForm('.form-upload-control"');
    this.initFancybox();
    this.initButtonGoTop("#pageTop");
  };
  return this.init();
};
export default Core;
