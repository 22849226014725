/**--------------------------------------
 * App Sticky
 *--------------------------------------*/
"use strict";
import { WINDOW, CLASS_HEADER } from "@utils/constants";
import { isExists, isMobile } from "@utils/validate";

/**
 * Sticky thisect
 */
const Sticky = function () {
  const IS_SHOW = "show",
    IS_HIDE = "hide";

  /**
   * addSticky function
   */
  this.addSticky = () => {
    $(CLASS_HEADER).addClass(IS_SHOW).removeClass(IS_HIDE);
  };
  /**
   * removeSticky function
   */
  this.removeSticky = () => {
    $(CLASS_HEADER).removeClass(IS_SHOW).addClass(IS_HIDE);
  };

  /**
   * handleScroll function
   * @returns void
   */
  this.handleScroll = () => {
    let self = this;
    let timer;
    let scrollPos = 0;

    function handleOnScroll() {
      if (isMobile()) return;
      let newVal = document.body.getBoundingClientRect().top;

      clearTimeout(timer);
      timer = setTimeout(function () {
        self.addSticky();
      }, 800);

      // detects new state and compares it with the new one
      newVal > scrollPos ? self.addSticky() : self.removeSticky();
      // saves the new position for iteration.
      scrollPos = newVal;
    }

    WINDOW.on("scroll", function (e) {
      WINDOW.scrollTop() > 100 && handleOnScroll();
    });
  };

  /**
   * handleStickMenu
   * @return {void}
   */
  this.init = function () {
    if (!isExists(CLASS_HEADER)) return;
    let self = this;

    WINDOW.on("resize", function (e) {
      self.handleScroll();
    });
    WINDOW.trigger("resize");
  };

  return this.init();
};

export default Sticky;
