/**--------------------------------------
 * App Constant
 *--------------------------------------*/
"use strict";
const WINDOW = $(window);
const DOCUMENT = $(document);
const BREAKPOINT_MOBILE = 767;
const BREAKPOINT_TABLET = 991;

const CLASS_BODY = "body";
const CLASS_HEADER = ".header";
const CLASS_MAIN_CONTENT = ".main-content";
const CLASS_FOOTER = ".footer";
const CLASS_PC = "pc";
const CLASS_SP = "sp";
const CLASS_HIDDEN = "overflow-hidden";
const CLASS_ACTIVE = "active";
// Message
const MESS_NOT_DEFINE = "Element is not defined!";

export {
  WINDOW,
  DOCUMENT,
  BREAKPOINT_MOBILE,
  BREAKPOINT_TABLET,
  CLASS_BODY,
  CLASS_HEADER,
  CLASS_MAIN_CONTENT,
  CLASS_FOOTER,
  CLASS_PC,
  CLASS_SP,
  CLASS_HIDDEN,
  MESS_NOT_DEFINE,
  CLASS_ACTIVE
};
