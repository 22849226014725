/**--------------------------------------
 * App Validate
 *--------------------------------------*/
"use strict";
import { getWindowWidth } from "./helpers";
import { BREAKPOINT_MOBILE, BREAKPOINT_TABLET, MESS_NOT_DEFINE } from "./constants";
/**
 * isExists function
 * @param {string} el
 */
export const isExists = el => typeof el != "undefined" && el != "null" && $(el).length > 0;
/**
 * getErrorLog function
 * @param {string} el
 */
export const showLog = mess => console.error(`${(mess && mess) || MESS_NOT_DEFINE} `);

/**
 * isMobile function
 */
export const isMobile = () => (getWindowWidth() < BREAKPOINT_MOBILE ? true : false);

/**
 * isTablet function
 */
export const isTablet = () =>
  getWindowWidth() > BREAKPOINT_MOBILE && getWindowWidth() < BREAKPOINT_TABLET ? true : false;

/**
 * isTabletAndMobile function
 */
export const isTabletAndMobile = () => (getWindowWidth() < BREAKPOINT_TABLET ? true : false);

export const isPC = () => (getWindowWidth() > BREAKPOINT_TABLET ? true : false);

export const isDevice = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    navigator.userAgent.toLowerCase()
  );
};
